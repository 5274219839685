<template>
  <div>
    <v-tabs
      v-model="currentTab"
      class="pb-4"
    >
      <v-tab :disabled="allDisabled || ordersDisabled">
        Proponowane zlecenia
      </v-tab>
      <v-tab :disabled="clientType === 'Osoba fizyczna' || allDisabled">
        Produkty
      </v-tab>
      <v-tab :disabled="allDisabled">
        Sprawdź cenę
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTab"
      v-if="!allDisabled"
    >
      <v-tab-item>
        <v-data-table
          v-if="!ordersDisabled"
          v-model="selectedOrder"
          :headers="ordersTableHeaders"
          :items="lastOrdersList"
          :loading="searchProcessing"
          v-bind="tableAttrs"
          @click:row="$emit('populateOrder', $event)"
        />
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          v-model="selectedProduct"
          :headers="productsTableHeaders"
          :items="sortedProductsList"
          :loading="searchProcessing"
          v-bind="tableAttrs"
          @click:row="$emit('populateProduct', $event)"
        />
      </v-tab-item>
      <v-tab-item>
        <div class="d-flex px-3 mt-6">
          <v-select
            outlined
            label="Promień wyszukiwania"
            v-model="priceCheckRadius"
            :menu-props="{offsetY: true}"
            :items="radiusOptions"
            item-text="text"
            item-value="value"
            append-icon="mdi-chevron-down"
            :rules="[rules.required]"
          />
          <v-btn
            color="primary"
            class="ml-4 base-hover"
            :disabled="!isPriceCheckAllowed"
            :loading="priceCheckProcessing"
            @click="checkPrice"
          >
            Sprawdź
          </v-btn>
        </div>

        <v-data-table
          :headers="priceCheckTableHeaders"
          :items="priceCheckOrders"
          :loading="searchProcessing"
          height="240"
          v-bind="tableAttrs"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import api from '../../../api/v1'
import rules from '../../../utils/validators'
import { formatValue } from '../../../utils/typesEnum'
import { mapState, mapActions } from 'vuex'
const ordersTableHeaders = [
  { text: 'Nr ref.', value: 'refNumber', sortable: false },
  { text: 'Zamówiony typ kontenera', value: 'containerType.name', sortable: false },
  { text: 'Typ odpadu/kruszywa', value: 'debrisType.displayName', sortable: false },
  { text: 'Cena netto', value: 'parsedNetValue', sortable: false, width: 100 },
]
const productsTableHeaders = [
  { text: 'Typ kontenera', value: 'containerTypeName', sortable: false },
  { text: 'Typ odpadu', value: 'debrisTypeDisplayName', sortable: true, width: 180 },
  { text: 'Kod odpadu', value: 'debrisTypeCode', sortable: true, width: 120 },
  { text: 'Cena netto', value: 'parsedNetValue', sortable: true, width: 120 },
]
const priceCheckTableHeaders = [
  { text: 'Adres', value: 'formattedAddress' },
  { text: 'Cena netto', value: 'debrisNetValue', width: 120 },
  { text: 'Cena brutto', value: 'debrisGrossValue', width: 120 },
]
const radiusOptions = [
  { value: 500, text: '500 m' },
  { value: 1000, text: '1 km' },
  { value: 2000, text: '2 km' },
  { value: 3000, text: '3 km' },
  { value: 5000, text: '5 km' },
  { value: 10000, text: '10 km' },
  { value: 15000, text: '15 km' },
]
const tableAttrs = {
  class: 'results-table',
  itemsPerPage: -1,
  hideDefaultFooter: true,
  fixedHeader: true,
  singleSelect: true,
}

export default {
  props: {
    orderData: {
      type: Object,
      required: true
    },
    courseType: {
      type: String,
      default: 'Podstawienie'
    },
    selectedAddress: {
      type: Object,
      default: () => {}
    },
    client: {
      type: Object,
      default: () => {}
    },
    searchProcessing: {
      type: Boolean,
      default: false
    },
    allDisabled: {
      type: Boolean,
      default: false
    },
    ordersDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rules,
    priceCheckTableHeaders,
    ordersTableHeaders,
    productsTableHeaders,
    tableAttrs,
    radiusOptions,
    selectedProduct: [],
    selectedOrder: [],
    priceCheckOrders: [],
    lastOrdersList: [],
    priceCheckProcessing: false,
    priceCheckRadius: 2000,
    currentTab: 0,
  }),
  computed: {
    ...mapState({
      department: state => state.core.department,
      sortedProductsList: state => state.orders.newOrderProducts?.slice().sort((a, b) =>
        a.debrisTypeDisplayName.localeCompare(b.debrisTypeDisplayName)
      ).filter(product => !product.fromLastDoneOrder), // product from last done order is only needed for price check, not for displaying in the list
    }),
    isPriceCheckAllowed () {
      const { lng, lat } = this.selectedAddress?.location || {}
      return this.department.id && this.priceCheckRadius && lat && lng
    },
    clientType() {
      return this.client?.clientType
    }
  },
  watch: {
    clientType (type) {
      this.currentTab = Number(type === 'Firma') // 0 - orders, 1 - products
    },
    'orderData.addressId': {
      immediate: true,
      handler (id) {
        this.$store.commit('orders/SET_PRODUCTS', [])
        if (!id) return
        class FlatProduct {
          constructor(product = {}) {
            this.id = product.id
            this.netValue = product.netValue
            this.parsedNetValue = formatValue(product.netValue, 'price')
            this.containerTypeId = product.containerTypeId || product.containerType?.id
            this.containerTypeName = product.containerTypeName || product.containerType?.name
            this.debrisTypeId = product.debrisTypeId || product.debrisType?.id
            this.debrisTypeName = product.debrisTypeName || product.debrisType?.name
            this.debrisTypeDisplayName = product.debrisTypeDisplayName || product.debrisType?.displayName
            this.debrisTypeClientDisplayName = product.debrisTypeClientDisplayName || product.debrisType?.clientDisplayName
            this.debrisTypeCode = product.debrisTypeCode || product.debrisType?.code
            this.debrisTypeAggregate = product.debrisTypeAggregate || product.debrisType?.aggregate
          }
        } // tmp fix as there are currently two types of objects at BE
        const products = this.selectedAddress.products?.map(product => new FlatProduct(product))
        this.$store.commit('orders/SET_PRODUCTS', products)
        this.$nextTick(this.getLastOrders())
      }
    }
  },
  mounted () {
    if (this.ordersDisabled) this.currentTab = 2
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar'
    }),
    getLastOrders () {
      const { id: clientId } = this.client || {}
      const { addressId } = this.orderData
      if (!clientId || !addressId) return
      const orderType = this.courseType === 'Transport' ? 'transport' : 'standard'
      api.getClientLastDoneOrder(clientId, { addressId, orderType, done: true })
        .then((resp) => {
          this.lastOrdersList = resp.data.map(order => {
            const { debrisNetValue, transportNetValue } = order.payment
            return {
              ...order,
              parsedNetValue: formatValue(+debrisNetValue + +transportNetValue, 'price')
            }
          })
        })
    },
    checkPrice () {
      const { lng, lat } = this.selectedAddress?.location
      const { containerTypeId, debrisTypeId } = this.orderData

      const params = {
        departmentId: this.department.id,
        containerTypeIds: [containerTypeId],
        debrisTypeIds: [debrisTypeId],
        location: {
          lat,
          lng,
          radius: this.priceCheckRadius,
        }
      }
      if (this.isPriceCheckAllowed) {
        this.priceCheckProcessing = true
        api.getSimilarOrdersPrices(params)
          .then((resp) => {
            this.priceCheckOrders = resp.data.map((order) => ({
              formattedAddress: order.address.formattedAddress,
              transportNetValue: formatValue(order.payment.transportNetValue, 'price'),
              debrisNetValue: formatValue(order.payment.debrisNetValue, 'price'),
              debrisGrossValue: formatValue(order.payment.debrisGrossValue, 'price'),
            }))
            if (!resp.data.length) {
              this.showSnackbar({ type: 'error', message: ['Nie znaleziono powiązanych zleceń'] })
            }
          })
          .finally(() => {
            this.priceCheckProcessing = false
          })
      } else {
        this.showSnackbar({ type: 'error', message: ['Niepoprawna konfiguracja'] })
      }
    }
  }

}
</script>
